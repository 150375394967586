import React, { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { tipoServicoAction, tipoVeiculoAction, estadoAction, ordemServicoAction } from '_actions'

export const ContextFiltros = createContext();

export const ContextFiltrosProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('8');
  const [state, setState] = useState('');
  const [direction] = useState('desc');
  const [order] = useState('data');
  const [typeVehicle, setTypeVehicle] = useState('');
  const [typeService, setTypeService] = useState('');

  useEffect(() => {
    dispatch(tipoServicoAction.getTiposServicoSelect())
    dispatch(tipoVeiculoAction.getTipoVeiculoSelect())
    dispatch(estadoAction.getEstadoSelect())
  }, [])

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { tipoVeiculo } = useSelector(
    (state) => state.tipoVeiculo
  )

  const { tipoServico } = useSelector(
    (state) => state.tipoServico
  )

  const { estado } = useSelector(
    (state) => state.estado
  )

  const nomeTipoVeiculo = tipoVeiculo?.find(t => t.value == typeVehicle)?.text;
  const nomeTipoServico = tipoServico?.find(t => t.value == typeService)?.text;
  const nomeEstado = estado?.find(t => t.value == state)?.text;

  const handleClearFilters = () => {
    setStatus(10);
    setState('');
    setTypeVehicle('');
    setTypeService('');

    // Chama a função fetchData logo após limpar os filtros
    const filterModel = {
      Page: 0,
      Limit: 10,
      Term: '',
      Status: '8',
      Order: order,
      Direction: direction,
      IdOrgao: (idOrgao && idOrgao != '' && idOrgao)
    };

    dispatch(ordemServicoAction.getOrdemServico(filterModel, true));
  }

  return (
    <ContextFiltros.Provider value={{
      status,
      setStatus,
      state,
      setState,
      typeVehicle,
      setTypeVehicle,
      typeService,
      setTypeService,
      handleClearFilters,
      nomeTipoVeiculo,
      nomeTipoServico,
      nomeEstado
    }}>
      {children}
    </ContextFiltros.Provider>
  );
};
