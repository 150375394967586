import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { stringUtils } from '../../utils';
import { ordemServicoAction } from '../../_actions';
import CustomButton from '../CustomButton';
import SearchIcon from '@mui/icons-material/Search';

const MapContainer = (props) => {

  const { classes, ordemServico, isEditable } = props;

  const dispatch = useDispatch();

  const [endereco, setEndereco] = useState('');

  useEffect(() => {
    //quando não houver localização setada, pega a do usuário 
    if (!props.latitude && !props.longitude) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            var changeLatUser = {
              type: 'select',
              target: {
                value: position.coords.latitude
              }
            };
            dispatch(ordemServicoAction.onChangeProps('latitude', changeLatUser))

            var changeLongUser = {
              type: 'select',
              target: {
                value: position.coords.longitude
              }
            };
            dispatch(ordemServicoAction.onChangeProps('longitude', changeLongUser))
          },
          error => {
            console.error('Error ao obter a localização do usuário:', error);
          }
        );
      } else {
        console.error('Geolocation não é suportada por esse navegador.');
      }
    } else
      if (props && props.latitude && props.longitude) {
        displayMarkers();
      }

  }, [])

  const getLatitudeLongitude = () => {

    if (endereco) {
      const geocoder = new props.google.maps.Geocoder();

      geocoder.geocode({ address: endereco }, (response) => {
        if (response?.length) {
          let changeLatEvent = {
            type: 'text',
            target: {
              value: response[0]?.geometry?.location?.lat()
            }
          };
          dispatch(ordemServicoAction.onChangeProps('latitude', changeLatEvent));

          let changeLngEvent = {
            type: 'text',
            target: {
              value: response[0]?.geometry?.location?.lng()
            }
          };
          dispatch(ordemServicoAction.onChangeProps('longitude', changeLngEvent));
        } else if (response?.status === 'REQUEST_DENIED') {
          toast.error('Ocorreu um problema ao realizar a busca do endereço')
        } else {
          toast.error('O endereço informado não foi localizado')
        }
      });
    } else {
      toast.error('Digite um endereço')
    }
  }

  const handleMarkerDrag = (map) => {
    const geocoder = new props.google.maps.Geocoder();

    geocoder.geocode({ location: { lat: map.position.lat(), lng: map.position.lng() } }, (response) => {

      if (response?.length) {
        setEndereco(response[0].formatted_address.toUpperCase())
        let changeLatEvent = {
          type: 'text',
          target: {
            value: map.position.lat()
          }
        };
        dispatch(ordemServicoAction.onChangeProps('latitude', changeLatEvent));

        let changeLngEvent = {
          type: 'text',
          target: {
            value: map.position.lng()
          }
        };
        dispatch(ordemServicoAction.onChangeProps('longitude', changeLngEvent));
      } else {
        toast.error('Ocorreu um problema ao utliziar esta localização')
      }
    })

  };

  const displayMarkers = () => {
    return (
      <Marker
        key={'marker'}
        id={0}
        position={{ lat: props.latitude, lng: props.longitude }}
        icon={require('../../img/rotorR.png')}
        draggable={isEditable(ordemServico.ultimoEvento) == false ? false : true}
        onDragend={(e, map) => handleMarkerDrag(map)}
        tracksViewChanges
      />
    );
  };

  return (
    <>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Typography>
          <strong>Endereço da Ordem de Serviço</strong>
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} xl={8} xs={12}>
          <TextValidator
            className={classes.textField}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            id="endereco"
            label="Endereço"
            disabled={isEditable(ordemServico.ultimoEvento) == false}
            margin="normal"
            onInput={stringUtils.toInputUppercase}
            onChange={(event) => setEndereco(event.target.value)}
            value={endereco}
            variant="outlined"
          />
        </Grid>
        <Grid item lg={2} md={2} xl={2} xs={12}>
          <CustomButton
            variant="outlined"
            onClick={getLatitudeLongitude}
            disabled={isEditable(ordemServico.ultimoEvento) == false || props.ordemServico.isLoading}
            style={{ marginTop: '16px', height: '3.5rem' }}
            startIcon={<SearchIcon />}
            endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
          >
            {props.ordemServico.isLoading ? 'Pesquisando...' : 'Pesquisar'}
          </CustomButton>
        </Grid>
      </Grid>
      <Map
        google={props.google}
        style={{ height: "300px", top: '10px', left: 0, right: '24px', borderRadius: "10px" }}
        zoom={15}
        center={{ lat: props.latitude, lng: props.longitude }}
        initialCenter={{ lat: props.latitude, lng: props.longitude }}
      >
        {displayMarkers()}
      </Map>
    </>
  );
};

export default GoogleApiWrapper((props) => ({
  apiKey: 'AIzaSyC-vwQ2cXp0L71Rbldp6kfhyw2tP07NBcg',
}))(MapContainer);

