import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ordemServicoAction, tipoServicoAction, tipoVeiculoAction, estadoAction } from '../../_actions'
import SearchIcon from '@mui/icons-material/Search';

import {
  ButtonGroup,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  FormLabel,
  FormControl,
  Grid,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { STATUS_SERVICO } from '../../utils'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import { ContextFiltros } from '../../contexts/ContextFiltros';

const ButtonFilter = styled(Button)(({ selected }) => ({
  color: '#000',
  backgroundColor: selected ? '#FFC107' : 'transparent',
  fontSize: 11,
  border: '1px solid #000',
  '&:hover': {
    backgroundColor: '#FFC107',
    border: '1px solid #000',
  },
  '&&:focus': {
    border: '1px solid #000',
    backgroundColor: '#FFC107',
    transition: '0.3s',
  },
}));

const CustomButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    textAlign: 'center',
    justifyContent: 'end',
    color: '#000'
  },
}));

const CustomTypography = styled(Typography)(({ theme, isFirst }) => ({
  justifyContent: 'left',
  textAlign: 'left',
  marginTop: isFirst ? 0 : 8, // Não aplica marginTop se for o primeiro
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

export const OrdemServicoModalFiltros = ({ modal, toggle, props, page, limit, idOrgao, order, direction }) => {

  const formRef = React.createRef(null);

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { status,
    setStatus,
    state,
    setState,
    typeVehicle,
    setTypeVehicle,
    typeService,
    setTypeService,
    handleClearFilters
  } = useContext(ContextFiltros);

  useEffect(() => {
    dispatch(tipoServicoAction.getTiposServicoSelect())
    dispatch(tipoVeiculoAction.getTipoVeiculoSelect())
    dispatch(estadoAction.getEstadoSelect())
  }, [])

  const { tipoVeiculo } = useSelector(
    (state) => state.tipoVeiculo
  )

  const { tipoServico } = useSelector(
    (state) => state.tipoServico
  )

  const { estado } = useSelector(
    (state) => state.estado
  )

  const handleClose = () => {
    toggle();
  }

  const handleApplyFilters = () => {
    let filterModel = {
      Page: page,
      Limit: limit,
      Order: status == STATUS_SERVICO.AVALIADO ? 'dataMudancaSituacao' : order,
      Direction: direction,
      IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
      Status: status == 10 ? '8' : status,
      TiposVeiculos: typeVehicle,
      TiposServicos: typeService,
      Estados: state
    };

    dispatch(ordemServicoAction.getOrdemServico(filterModel, true));
    toggle();
  };

  const buttons = [
    <ButtonFilter key="0" size="small" variant="outlined" selected={status == ''} onClick={() => setStatus('')}>Em lançamento</ButtonFilter>,
    <ButtonFilter key="1" size="small" variant="outlined" selected={status == '1'} onClick={() => setStatus('1')}>Pendentes</ButtonFilter>,
    <ButtonFilter key="2" size="small" variant="outlined" selected={status == '2'} onClick={() => setStatus('2')}>Recebendo Orçamentos</ButtonFilter>,
    <ButtonFilter key="3" size="small" variant="outlined" selected={status == '3'} onClick={() => setStatus('3')}>Em execução</ButtonFilter>,
    <ButtonFilter key="4" size="small" variant="outlined" selected={status == '4'} onClick={() => setStatus('4')}>Recusadas</ButtonFilter>,
    <ButtonFilter key="5" size="small" variant="outlined" selected={status == '5'} onClick={() => setStatus('5')}>Concluídas</ButtonFilter>,
    <ButtonFilter key="6" size="small" variant="outlined" selected={status == '7'} onClick={() => setStatus('7')}>Verificadas</ButtonFilter>,
    <ButtonFilter key="7" size="small" variant="outlined" selected={status == '6'} onClick={() => setStatus('6')}>Avaliadas</ButtonFilter>,
    <ButtonFilter key="8" size="small" variant="outlined" selected={status == '8'} onClick={() => setStatus('8')}>Todas</ButtonFilter>
  ];

  const buttonsVehicleType = tipoVeiculo && tipoVeiculo.length > 0 && tipoVeiculo.map(t =>
    <ButtonFilter key={t.value} size="small" variant="outlined" selected={typeVehicle == t.value} onClick={() => setTypeVehicle(t.value)}>{t.text}</ButtonFilter>,
  )

  const buttonsServiceType = tipoServico && tipoServico.length > 0 && tipoServico.map(t =>
    <ButtonFilter key={t.value} size="small" variant="outlined" selected={typeService == t.value} onClick={() => setTypeService(t.value)}>{t.text}</ButtonFilter>,
  )

  const buttonsState = estado && estado.length > 0 && estado.map(t =>
    <ButtonFilter key={t.value} size="small" variant="outlined" selected={state == t.value} onClick={() => setState(t.value)}>{t.text}</ButtonFilter>,
  )

  function InsertText() {
    return (
      <Typography variant="h6">
        <b>{'Filtros'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='lg'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleApplyFilters(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Selecionar filtros para aplicar à listagem de Ordens de Serviços"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} xs={12} align="left">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography isFirst variant="subtitle1">Status</CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttons}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="left">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Tipo de Veículo</CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttonsVehicleType}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="left">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Tipo de Serviço</CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttonsServiceType}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="left">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Estado</CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttonsState}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={handleClearFilters}
                >
                  Limpar filtros
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.ordemServico.isLoading}
                  startIcon={<SearchIcon />}
                  endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                >
                  {props.ordemServico.isLoading ? 'Pesquisando...' : 'Aplicar filtros e pesquisar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
