import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, ordemServicoAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import TitleContentInformation from '../TitleContentInformation'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const RelatorioOcorrenciaOrdemServico = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [valueOrdemServico, setValueOrdemServico] = useState(null);
  const [inputValueOrdemServico, setInputValueOrdemServico] = useState('');
  const [openOrdemServico, setOpenOrdemServico] = useState(false)
  const [dataHoraInicio, setDataHoraInicio] = useState('');
  const [dataHoraFim, setDataHoraFim] = useState('');

  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { ordemServico } = useSelector(
    (state) => state.ordemServico)

  function getTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.length;

      return total;

    } else {
      return 0;
    }
  }

  useEffect(() => {
    dispatch(ordemServicoAction.getOrdemServicoSelect({ IdOrgao: idOrgao }));

  }, [idOrgao]);

  useEffect(() => {
    dispatch(relatorioAction.clear());
  }, [])

  const optionsOrdemServicos = ordemServico.map(u => ({ id: u.value, nome: u.text }));

  const handlePrint = () => {
    if ((valueOrdemServico && valueOrdemServico != undefined) || (dataHoraFim != null && dataHoraInicio != null)) {
      window.open('/relatorioOcorrenciasOrdemServicoPrint', '_blank');
    }
  }

  let payload = {
    IdOrgao: idOrgao,
    IdOrdemServico: valueOrdemServico ? valueOrdemServico.id : 0,
    DataIntervaloInicio: dataHoraInicio,
    DataIntervaloFim: dataHoraFim
  };

  const handleExportExcel = () => {
    dispatch(relatorioAction.getRelatorioOcorrenciaOrdemServicoArquivo(payload))
  }

  const handleSubmit = () => {
    if ((valueOrdemServico && valueOrdemServico != undefined) || (dataHoraFim != null && dataHoraInicio != null)) {
      if (dataHoraFim >= dataHoraInicio) {
        dispatch(relatorioAction.getRelatorioOcorrenciaOrdemServico(payload, true));
      } else {
        toast.error('Data do intervalo de início não pode ser maior que a data de intervalo de fim')
      }
    } else {
      toast.error('Selecione uma ordem de serviço ou um intervalo de datas!')
    }
  };

  useEffect(() => {
    if (valueOrdemServico) {
      handleChangeAutoCompleteOrdemServico(valueOrdemServico)
    }
  }, [valueOrdemServico])

  const handleChangeAutoCompleteOrdemServico = (obj) => {
    if (obj != null) {
      let selectedOrdemServico = ordemServico.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        ordemServicoAction.changeDetailsOrdemServico(
          selectedOrdemServico[0]
        )
      )
    } else {

    }
    setOpenOrdemServico(false)
  }

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.relatorio.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Ocorrências de uma Ordem de Serviço">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioOcorrenciasOrdemServico"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Ocorrências de uma Ordem de Serviço'}
                    />
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} xl={6} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueOrdemServico}
                          onChange={(event, newValue) => {
                            setValueOrdemServico(newValue);
                          }}
                          inputValue={inputValueOrdemServico}
                          onInputChange={(event, newInputValueOrdemServico) => {
                            setInputValueOrdemServico(newInputValueOrdemServico);
                          }}
                          id="controllable-states-demo"
                          options={optionsOrdemServicos}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueOrdemServico}
                          getOptionLabel={(o) => (o ? o.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Ordem de Serviço... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data Intervalo Início"
                          margin="normal"
                          onChange={(e) => setDataHoraInicio(e.target.value)}
                          value={dataHoraInicio || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data Intervalo Fim"
                          margin="normal"
                          onChange={(e) => setDataHoraFim(e.target.value)}
                          value={dataHoraFim || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                          >
                            Gerar PDF
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="button"
                            onClick={() => handleExportExcel()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                          >
                            Exportar para Excel
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>DATA</strong></TableCell>
                        <TableCell><strong>OCORRÊNCIA</strong></TableCell>
                        <TableCell><strong>USUÁRIO</strong></TableCell>
                        <TableCell><strong>ÓRGÃO</strong></TableCell>
                        <TableCell><strong>EMPRESA</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.dataHora ? moment(n.dataHora).format('DD/MM/yyyy HH:mm') : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.descricaoOcorrencia ? n.descricaoOcorrencia.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.usuario ? n.usuario.toUpperCase() : '-'} (<span style={{ fontSize: 11 }}>{n.usuarioEmail}</span>)
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.orgao ? n.orgao.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.empresa ? n.empresa.toUpperCase() : '-'}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        ((valueOrdemServico && valueOrdemServico != undefined) || (dataHoraFim != null && dataHoraInicio != null) ?
                          (isLoading ?
                            Array.apply(null, { length: 6 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={6} colSpan={5} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={5} align="center">Selecione uma ordem de serviço ou um intervalo de datas para prosseguir</TableCell>
                          </TableRow>
                        )
                      }
                      {undefined !== relatorio && relatorio.length
                        ?
                        <TableRow>
                          <TableCell rowSpan={2} colSpan={4}><b>TOTAL DE OCORRÊNCIAS</b></TableCell>
                          <TableCell rowSpan={2}><b>{getTotal()}</b></TableCell>
                        </TableRow>
                        :
                        null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
RelatorioOcorrenciaOrdemServico.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioOcorrenciaOrdemServicoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioOcorrenciaOrdemServico))
)
export { connectedRelatorioOcorrenciaOrdemServicoPage as RelatorioOcorrenciaOrdemServico }
